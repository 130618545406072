import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerLista() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },
  mostrarLista(id) {
    var url = ConfigAPI.baseURL + "price-list/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLista(data, file_1, file_2) {  
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file_1" && key!="file_2") {
        formData.append(key, data[key]);
      }

      if(key=="file_1") {                
        file_1.forEach((value, key) => {          
          formData.append('file_1', value);
        })
      }

      if(key=="file_2") {                
        file_2.forEach((value, key) => {          
          formData.append('file_2', value);
        })
      }      
    }
          
    var params = formData
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarLista(data, file_1, file_2) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file_1" && key!="file_2") {
        formData.append(key, data[key]);
      }

      if(key=="file_1") {                
        file_1.forEach((value, key) => {          
          formData.append('file_1', value);
        })
      }

      if(key=="file_2") {                
        file_2.forEach((value, key) => {          
          formData.append('file_2', value);
        })
      }      
    }
          
    var params = formData
    var url = ConfigAPI.baseURL + "price-list/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarLista(id) {
    var url = ConfigAPI.baseURL + "price-list/"+ id + Session.getToken();
    return instance.delete(url);
  },
  filtrarLista(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },   

  obtenerListaProductos() {
    var url = ConfigAPI.baseURL + "price-list-products" + Session.getToken();
    return instance.get(url);
  },
  mostrarListaProductos(id) {
    var url = ConfigAPI.baseURL + "price-list-products/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarListaProductos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "price-list-products-lot" + Session.getToken();
    return instance.post(url,params);
  },
  editarListaProductos(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-products-lot/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarListaProductos(id) {
    var url = ConfigAPI.baseURL + "price-list-products/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  obtenerListaProductosByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-products/filter/getProducts" + Session.getToken();
    return instance.post(url,params);
  },
  mostrarProductosByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-products/filter/showProducts" + Session.getToken();
    return instance.post(url,params);
  },
  
  obtenerListaCliente() {
    var url = ConfigAPI.baseURL + "price-list-customers" + Session.getToken();
    return instance.get(url);
  },
  mostrarListaCliente(id) {
    var url = ConfigAPI.baseURL + "price-list-customers/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarListaCliente(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "price-list-customers" + Session.getToken();
    return instance.post(url,params);
  },
  editarListaCliente(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-customers/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarListaCliente(id) {
    var url = ConfigAPI.baseURL + "price-list-customers/"+ id + Session.getToken();
    return instance.delete(url);
  },   

  obtenerMoneda() {
    var url = ConfigAPI.baseURL + "price-currency" + Session.getToken();
    return instance.get(url);
  },
  mostrarMoneda(id) {
    var url = ConfigAPI.baseURL + "price-currency/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarMoneda(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "price-currency" + Session.getToken();
    return instance.post(url,params);
  },
  editarMoneda(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "price-currency/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarMoneda(id) {
    var url = ConfigAPI.baseURL + "price-currency/"+ id + Session.getToken();
    return instance.delete(url);
  },  

  obtenerListaCursos() {
    var url = ConfigAPI.baseURL + "price-list-courses" + Session.getToken();
    return instance.get(url);
  },
  mostrarListaCursos(id) {
    var url = ConfigAPI.baseURL + "price-list-courses/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarListaCursos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "price-list-courses-lot" + Session.getToken();
    return instance.post(url,params);
  },
  editarListaCursos(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-courses-lot/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarListaCursos(id) {
    var url = ConfigAPI.baseURL + "price-list-courses/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  obtenerListaCursosByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-courses/filter/getCourses" + Session.getToken();
    return instance.post(url,params);
  },
  mostrarCursosByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-courses/filter/showCourses" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerListaServices() {
    var url = ConfigAPI.baseURL + "price-list-services" + Session.getToken();
    return instance.get(url);
  },
  mostrarListaServices(id) {
    var url = ConfigAPI.baseURL + "price-list-services/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarListaServices(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "price-list-services-lot" + Session.getToken();
    return instance.post(url,params);
  },
  editarListaServices(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-services-lot/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarListaServices(id) {
    var url = ConfigAPI.baseURL + "price-list-services/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  obtenerListaServicesByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-services/filter/getServices" + Session.getToken();
    return instance.post(url,params);
  },
  mostrarServicesByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-services/filter/showServices" + Session.getToken();
    return instance.post(url,params);
  },    

  obtenerProducto(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products/filter/not-in-list" + Session.getToken();
    return instance.post(url,params);
  },    
  obtenerCurso(data) {
    var params = data
    var url = ConfigAPI.baseURL + "courses/filter/not-in-list" + Session.getToken();
    return instance.post(url,params);
  },   
  obtenerServices(data) {
    var params = data
    var url = ConfigAPI.baseURL + "services/filter/not-in-list" + Session.getToken();
    return instance.post(url,params);
  },  

}

export default servicesAPI;
